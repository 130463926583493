<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
      <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
      </div>
    </v-row><br><br>
    <br />
    <v-card class="card">
      <v-card-title class="heading justify-center">
        <span>BookEdition</span>
      </v-card-title>
      <div v-if="init_loading">
        <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-card-text>
          <div class="add-section">
            <div style="text-align: center">
              <v-col>
                <v-form ref="form">
                  <label class="add-text">Add Book Edition</label><span style="color: red;"> *</span>
                  <v-text-field class="text" v-model="edition" outlined dense :rules="[rules.required]" autocomplete="off"
                    clearable required></v-text-field>
                  <label class="add-text">Display Name</label><span style="color: red;"> *</span>
                  <v-text-field class="text" v-model="displayname" outlined dense :rules="[rules.required]"
                    autocomplete="off" clearable required></v-text-field>

                  <v-btn color="success" @click="addedition">Add</v-btn>
                </v-form>
              </v-col>
            </div>
          </div>

          <template>
            <div class="text-center">
              <v-dialog v-model="dialog" width="500">
                <v-card>
                  <v-card-title class="grey lighten-2"> Edit Book Edition </v-card-title>

                  <v-card-text style="padding-bottom: 0px">
                    <div class="add-section">
                      <div style="text-align: center">
                        <v-col>
                          <v-form ref="form">
                            <label class="add-text">Add New Book Edition</label><span style="color: red;"> *</span>
                            <v-text-field class="text" v-model="editedition" outlined dense :rules="[rules.required]"
                              autocomplete="off" clearable required></v-text-field>
                            <label class="add-text">Display Name</label><span style="color: red;"> *</span>
                            <v-text-field class="text" v-model="editdisplayname" outlined dense :rules="[rules.required]"
                              autocomplete="off" clearable required></v-text-field>
                          </v-form>
                        </v-col>
                      </div>
                    </div>
                  </v-card-text>

                  <!-- <v-divider></v-divider> -->

                  <div class="text-center">
                    <v-spacer></v-spacer>
                    <v-btn class="m-3" @click="dialog = false"> Close </v-btn>
                    <v-btn class="m-3 green white--text" @click="saveEdit()">Update</v-btn>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </template>

          <div v-if="init_loading">
            <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
          </div>
          <div v-else>

            <template v-if="editiondata != null">
              <v-data-table :headers="headers" :items="editiondata" :search="search" class="elevation-1 nowrap">
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                      hide-details></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:item.edit="{ item }">
                  <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                  <v-icon v-if="item" class="mr-2" @click="updateedition(item)">mdi mdi-pencil-box</v-icon>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                  <v-switch v-model="item.isactive" @change="editItem(item)"></v-switch>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-icon v-if="item" class="mr-2" @click=" deleteedition(item.id)">
                    mdi mdi-delete</v-icon>
                </template>
              </v-data-table>
            </template>
          </div>
        </v-card-text>
      </div>
    </v-card>
    <!-- <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
  </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    init_loading: false,
    loader: false,
    snackbar_msg: "",
    snackbar: false,
    color: "",
    org: null,
    dialog: false,
    search: "",
    edition: null,
    displayname: null,
    editedition: null,
    editdisplayname: null,
    editionid: null,
    userType: null,
    edit_btn_load: false,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Admin Master',
        disabled: false,
        href: '/admin-master',
      },
      {
        text: 'Book Edition',
        disabled: true,
        href: '',
      },
    ],
    rules: {
      required: (value) => !!value || "Required.",
    },

    editiondata: null,
    headers: [
      { text: "Sr no", value: "srno" },
      { text: "Edition", value: "edition" },
      { text: "Display", value: "displayname" },
      { text: "Edit", value: "edit" },
      { text: "Toggle", value: "actions", sortable: false },
      { text: "Delete", value: "delete" },
      // { text: "Toggle", value: "actions", sortable: false },
    ],
  }),

  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    editItem(item) {
      console.log(item);
      axios
        .post("/admin/editionIsactive", item)
        .then(() => {
          // this.sec_mode_list = res.data.user_list;
          this.showSnackbar("#4CAF50", "Edition updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    updateedition(item) {
      this.dialog = true;
      this.editionid = item.id;
      this.editedition = item.edition;
      this.editdisplayname = item.displayname;
    },

    saveEdit() {
      if (this.editedition) {
        this.editedition = this.editedition.trim();
      }
      if (this.editdisplayname) {
        this.editdisplayname = this.editdisplayname.trim();
      }

      const data = {
        editionid: this.editionid,
        editedition: this.editedition,
        editdisplayname: this.editdisplayname,
      };

      if (this.editedition && this.editdisplayname) {
        this.loader = true;
        axios
          .post("/admin/editEdition", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.loader = false;
              this.showSnackbar("#4CAF50", "Edition updated successfully...");
              this.editedition = "";
              this.editdisplayname = "";

              this.dialog = false;
              this.onload();
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            this.loader = false;
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.showSnackbar("#b71c1c", "Please enter edition ");
        this.$refs.form.validate();
      }
    },
    deleteedition(item) {
      const data = {
        editionid: item,
      };
      axios
        .post("/admin/deleteEdition", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", "edition  Deleted Successfully  !!");
            this.onload();
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          window.console.log(error);
        });
    },

    onload() {
      this.init_loading = true;
      axios.post("/admin/getEdition").then((res) => {
        this.init_loading = false;
        if (res.data.msg == "200") {
          this.editiondata = res.data.editiondata;
          console.log(this.editiondata);
        } else {
          this.init_loading = false;
        }
      });
    },

    addedition() {
      if (this.edition == null) {
        this.showSnackbar("#b71c1c", "Please Enter Edition.. ");
        return;
      }
      if (this.displayname == null) {
        this.showSnackbar("#b71c1c", "Please Enter Display Name.. ");
        return;
      }

      const data = {

        edition: this.edition,
        displayname: this.displayname,
      };


      this.loader = true;
      axios
        .post("/admin/saveEdition", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.loader = false;
            this.showSnackbar("#4CAF50", "Edition Added successfully...");
            this.edition = "";
            this.displayname = "";
            this.onload();
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          this.loader = false;
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });

    },
  },
  mounted() {
    this.onload();
  },
};
</script>
<style scoped>
.heading {
  background-color: #3f51b5;
  padding: 0.3rem;
  color: white;
}

.v-icon {
  cursor: pointer;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
